<template>
  <q-layout v-if="validUser || !$auth.isAuthenticated.value" view="lHh Lpr lFf">
    <Header/>
    <Menu :userProfile="userProfile"/>
    <Footer :build-info="buildInfo" :vue-version="vueVersion" :quasar-version="quasarVersion"/>

    <q-page-container>
      <router-view/>
    </q-page-container>
  </q-layout>

  <ErrorBannerRedakteure v-else-if="!userProfileLoading && validUser === false"/>
  <ErrorBannerTechnischerFehler v-else-if="!userProfileLoading && validUser === null"/>
  <SpinnerStart v-if="userProfileLoading"/>
</template>

<script>
import {ref} from 'vue'
import {date, useQuasar} from 'quasar'
import Header from "@/components/Header";
import Menu from "@/components/Menu";
import Footer from "@/components/Footer";
import ErrorBannerRedakteure from "@/components/ErrorBannerRedakteure";
import ErrorBannerTechnischerFehler from "@/components/ErrorBannerTechnischerFehler";
import SpinnerStart from "@/components/SpinnerStart";
import useGetUserProfile from "@/composables/getUserProfile";
import { version as VueVersion } from 'vue';

export default {
  name: 'LayoutDefault',

  components: {Header, Menu, Footer, ErrorBannerRedakteure, ErrorBannerTechnischerFehler, SpinnerStart},

  setup() {
    const $q = useQuasar()
    const {getUserProfile} = useGetUserProfile()
    const userProfile = ref(null)
    const userProfileLoading = ref(true)
    const validUser = ref(null)

    const buildInfo = ref({
      hash: process.env.VUE_APP_SEIYUU_BUILD_GIT_HASH,
      date: date.formatDate(Number(process.env.VUE_APP_SEIYUU_BUILD_DATE), 'DD.MM.YYYY HH:mm:ss ZZ')
        .replace('+0100', 'CET')
        .replace('+0200', 'CEST')
    })

    const vueVersion = ref(VueVersion)
    const quasarVersion = $q.version

    userProfileLoading.value = true
    getUserProfile().then(({user, error}) => {
      if (error === null && user.info.rolle.id > 0) {
        validUser.value = true
        userProfile.value = user
      } else if (error.request.status === 403) {
        // nur für Redakteure
        validUser.value = false
      } else {
        validUser.value = null
      }
      userProfileLoading.value = false
    }).catch(() => {
      userProfileLoading.value = false
      // TODO: push error toast
    })

    return {
      leftDrawerOpen: ref(false),
      userProfile,
      userProfileLoading,
      validUser,
      buildInfo,
      vueVersion,
      quasarVersion,
    }
  }
}
</script>
