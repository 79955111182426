<template>
  <q-drawer v-model="leftDrawerOpen" show-if-above bordered class="bg-grey-2">

    <q-scroll-area style="height: calc(100% - 150px); margin-top: 150px; border-right: 1px solid #ddd">
      <q-list padding>
        <MenuItem name="Startseite" icon="home" :to="{name: 'Home'}"/>
        <MenuItem name="Personen" icon="people" :to="{name: 'Personen'}"/>
        <MenuItem name="Synchronfirmen" icon="business" :to="{name: 'Synchronfirmen'}"/>
        <MenuItem name="Filme (mit Arbeitsbedarf)" icon="movie" :to="{name: 'FilmeLegacy'}"/>
        <MenuItem name="Serien (mit Arbeitsbedarf)" icon="tv" :to="{name: 'SerieLegacy'}"/>
        <MenuItem name="Hörproben" icon="audio_file" :to="{name: 'Hörproben'}"/>
      </q-list>
    </q-scroll-area>

    <q-img class="absolute-top" src="@/assets/avatarbg.png" style="height: 150px">
      <div v-if="userProfile" class="absolute-top bg-transparent">
        <q-avatar size="56px" class="q-mb-sm">
          <img :src="userProfile.user.picture + '?sz=100'" :alt="userProfile.user.name">
        </q-avatar>
        <div class="text-weight-bold">{{ userProfile.user.name }}</div>
        <div>Rolle: {{ userProfile.info.rolle.bezeichnung }}</div>
      </div>
    </q-img>
  </q-drawer>
</template>

<script>
import MenuItem from "@/components/MenuItem";

export default {
  name: 'Menu',
  props: ["userProfile"],
  components: {MenuItem},
}
</script>
