<template>
  <q-footer reveal elevated>
    <q-toolbar>
      <div style="width: 100%" class="row reverse">
        <div>
          <small>Quasar {{ quasarVersion }}, Vue {{ vueVersion }}, Build-Datum: {{ buildInfo.date }}, Revision: <code>{{ buildInfo.hash }}</code></small>
        </div>
      </div>
    </q-toolbar>
  </q-footer>
</template>

<script>
export default {
  name: 'Footer',
  props: ['buildInfo', 'vueVersion', 'quasarVersion'],
}
</script>
