import axios from "axios";
import {authPlugin} from "@/auth";

const setupAxios = () => {
  axios.defaults.headers.post['Content-Type'] = "application/json"
  axios.defaults.headers.put['Content-Type'] = "application/json"
  axios.defaults.headers.patch['Content-Type'] = "application/json"

  axios.interceptors.request.use(async function (config) {
    // don't add the Authorization header for S3
    if (config.url.startsWith("https://s3.synchronkartei.de")) {
      return config
    }

    const accessToken = await authPlugin.getTokenSilently()
    config.headers.Authorization = `Bearer ${accessToken}`

    return config;
  });
}

export default setupAxios
